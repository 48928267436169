<template>
    <div
        class="item"
        @touchend="isActive = false"
        @touchstart="isActive = true"
        :class="{'color-feedback': isActive}">
        {{ content }}
    </div>
</template>

<script>

export default {
    name: 'DropdownItem',
    props: {
        content: String
    },
    data() {
        return {
            isActive: false,
        }
    },
}
</script>

<style scoped>
.color-feedback {
    background-color: var(--secondary-color);
}

.item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
</style>
