export default class Kneser {
    constructor(V) {
        this.V = V;
        this.shuffle(V);
        this.E = new Map();
        for (const v of V) {
            const adj = new Array();
            for (const vi of V) {
                if (this.areDisjoint(v, vi)) {
                    adj.push(vi);
                }
            }
            this.E.set(v, adj);
        }
    }

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    areDisjoint(v1, v2) {
        return v1 != v2 &&
            !v1.fencerA.hasParticipated(v2) &&
            !v1.fencerB.hasParticipated(v2);

    }

    areAdjacent(v1, v2) {
        return this.E.get(v1).includes(v2);
    }

    isHamiltonian() {
        const V = this.V;
        for (var i = 0; i < V.length - 1; i++) {
            if (!this.areDisjoint(V[i], V[i + 1])) {
                return false;
            }
        }
        return true;
    }

    reverseVBetween(i1, i2) {
        const V = this.V;
        var isReversed = false;
        while (!isReversed) {
            [V[i1], V[i2]] = [V[i2], V[i1]];
            isReversed = Math.abs(i1 - i2) < 2;
            i1 = (i1 + 1) % V.length;
            i2 = (((i2 - 1) % V.length) + V.length) % V.length;
        }
    }

    getHamilton() {
        const V = this.V;
        const cycleV = index => index % V.length;
        var i = 0;
        while (!this.isHamiltonian()) {
            var inext = cycleV(i + 1);
            if (!this.areAdjacent(V[i], V[inext])) {
                var j = cycleV(i + 2);
                var jnext;
                while ((jnext = cycleV(j + 1)) != i) {
                    if (this.areAdjacent(V[i], V[j]) &&
                        this.areAdjacent(V[inext], V[jnext])) {
                        this.reverseVBetween(inext, j);
                        break;
                    }
                    j = jnext;
                }
                if (jnext == i) {
                    for (var i = 0; i < V.length - 1; i++) {
                        if (!this.areDisjoint(V[i], V[i + 1])) {
                            const tail = V.splice(i + 1);
                            V.splice(0, 0, ...tail);
                        }
                    }
                    if (!this.isHamiltonian()) {
                        this.shuffle(V);
                    }
                }
            }
            i = inext;
        }
        return this.V;
    }
}
