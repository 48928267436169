<template>
    <Toolbar
        :fencers="fencers"
        @new-name="addFencer"
        @delete-all-fencers="deleteAllFencers"
        @delete-all-matches="deleteAllMatches"
        @points="setPoints"/>
    <div id="content">
        <Table
            :points="points"
            :fencers="fencers"
            @delete-match="deleteMatch"
            @add-match="addMatch"
            @delete-fencer="deleteFencer"
            @rename-fencer="renameFencer"/>
    </div>
    <Footer :fencers="fencers"/>
</template>

<script>
import Toolbar from './components/Toolbar.vue'
import Table from './components/Table.vue'
import Fencer from './js/Fencer.js'
import Match from './js/Match.js'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        Toolbar,
        Table,
        Footer
    },
    data() {
        return {
            points: 5,
            fencers: new Array(),
            isTestModalShowing: true
        }
    },
    created() {
        this.addFencer('Fechter1');
        this.addFencer('Fechter2');
        this.addFencer('Fechter3');
        this.addFencer('Fechter4');
    },
    methods: {
        deleteAllFencers() {
            this.fencers = new Array();
        },
        deleteAllMatches() {
            for (const fencer in this.fencers) {
                for (var i = 0; i < fencer; i++) {
                    this.deleteMatch(this.fencers[fencer].matches[i]);
                }
            }
        },
        setPoints(points) {
            this.points = points;
        },
        getCleanMatch(fencerA, fencerB) {
            const halfPoints = Math.round(this.points / 2);
            return new Match(fencerA, halfPoints, fencerB, halfPoints, null);
        },
        deleteMatch(match) {
            this.addMatch(this.getCleanMatch(match.fencerA, match.fencerB));
        },
        addMatch(match) {
            const fencerA = match.fencerA;
            const fencerB = match.fencerB;
            fencerA.matches[this.fencers.indexOf(fencerB)] = match;
            fencerB.matches[this.fencers.indexOf(fencerA)] = match;
        },
        getFencer(name) {
            for (const fencer of this.fencers) {
                if (name == fencer.name) {
                    return fencer;
                }
            }
        },
        deleteFencer(deletedName) {
            const fencer = this.getFencer(deletedName);
            for (const opponent of this.fencers) {
                opponent.matches.splice(this.fencers.indexOf(fencer), 1);
            }
            this.fencers.splice(this.fencers.indexOf(fencer), 1);
        },
        addFencer(name) {
            const newFencer = new Fencer(name);
            this.fencers.push(newFencer);
            for (const fencer of this.fencers) {
                const match = this.getCleanMatch(newFencer, fencer);
                newFencer.matches.push(match);
                if (!newFencer.isEqual(fencer)) {
                    fencer.matches.push(match);
                }
            }
        },
        renameFencer(names){
            this.getFencer(names.oldName).rename(names.newName);
        }
    }
}
</script>

<style>
:root {
    --primary-color: #001223;
    --secondary-color: #FFFF00;
    --thernary-color: #0a84ff;
    --header-size: 3.8em;
}

* {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html {
    touch-action: manipulation;
}

body {
    font-family: Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

#content {
    margin-top: var(--header-size);
}
</style>
