<template>
    <td :class="{'color-feedback': isActive}"
        @click="isStatsModalShowing = true"
        @touchstart="isActive = true"
        @touchend="isActive = false"
        v-touch:hold="() => isEditFencerModalShowing = true"
        class="cell">
        {{ fencer.name }}
    </td>
    <Teleport to="body">
        <Modal isInfo
            :is-modal-showing="isStatsModalShowing"
            @close="isStatsModalShowing = false">
            <template #header>
                {{ fencer.name }}
            </template>
            <template #body>
                <table class="stats-table">
                    <tr>
                        <td><b>Index 1</b></td>
                        <td><b>Index 2</b></td>
                    </tr>
                    <tr>
                        <td>{{ fencer.getIndex1() }}</td>
                        <td>{{ fencer.getIndex2() }}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><b>Platz</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ fencer.getPlace(fencers) }}</td>
                    </tr>
                </table>
            </template>
        </Modal>
    </Teleport>
    <EditFencerModal
        :fencer-name="fencer.name"
        :fencers="fencers"
        @close="isEditFencerModalShowing = false"
        @new-name="(newName) => $emit('renameFencer', newName)"
        @delete-fencer="(deletedName) => $emit('deleteFencer', deletedName)"
        :is-modal-showing="isEditFencerModalShowing"/>
</template>

<script>
import Modal from './Modal.vue'
import EditFencerModal from './EditFencerModal.vue'

export default {
    name: 'FencerCell',
    components: {
        Modal,
        EditFencerModal
    },
    props: {
        fencers: Array,
        fencer: Object
    },
    data() {
        return {
            isActive: false,
            isStatsModalShowing: false,
            isEditFencerModalShowing: false
        }
    },
    methods: {
    },
    emits: ['renameFencer', 'deleteFencer']
}
</script>

<style scoped>
.color-feedback {
    background-color: var(--secondary-color);
}

.stats-table {
    margin: auto;
    border-collapse: collapse;
}

table.stats-table td {
    text-align: center;
    border: medium none;
    padding: .5em;
}

table.stats-table tr:nth-child(1), table.stats-table tr:nth-child(3) {
    border-bottom: 1px solid black;
}

table.stats-table tr:nth-child(3) > td {
    padding-top: 1.3em;
}
</style>
