<template>
    <font-awesome-icon
        :icon="icon"
        :class="options"
        class="icon"/>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'IdleIcon',
    components: {
        FontAwesomeIcon
    },
    props: {
        icon: String,
        animationType: String,
        duration: {
            type: String,
            default: ''
        },
        delay: {
            type: String,
            default: ''
        },
        iterationCount: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isAnimationPlaying: false,
        }
    },
    computed: {
        options() {
            return [
                this.animationType,
                {'duration': this.duration != ''},
                {'iteration-count': this.iterationCount != ''},
                {'delay': this.delay != ''}
            ]
        },
    }
}
</script>

<style scoped>
.icon {
    font-size: 2.5ex;
    display: block;
    padding: 1ex;
}

.duration {
    --fa-animation-duration: v-bind(duration);
}

.delay {
    --fa-animation-delay: v-bind(delay);
}

.iteration-count {
    --fa-animation-iteration-count: v-bind(iterationCount);
}
</style>
