import { createApp } from 'vue'
import App from './App.vue'
import vClickOutside from "click-outside-vue3"
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGear, faUserPlus, faTrashCan, faTrophy, faAward, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import Vue3TouchEvents from "vue3-touch-events";
library.add(faGear, faUserPlus, faTrashCan, faTrophy, faAward, faClipboardList);

const app = createApp(App);
app.use(Vue3TouchEvents)
app.use(vClickOutside);
app.mount('#app');
