<template>
    <Teleport to="body">
        <Modal
            :is-modal-showing="isModalShowing"
            @confirm="addFencer"
            @abort="close"
            @close="close">
            <template #header>
                Fechter {{ isAdding ? 'hinzufügen' : 'bearbeiten' }}
            </template>
            <template #body>
                <div class="wrapper">
                    <input
                        v-model="newName"
                        class="fencer-input"
                        :class="isAdding ? 'add-fencer' : 'edit-fencer'"
                        :autofocus="isAdding"
                        placeholder="Name"
                        maxlength="30"
                        type="text">
                        <IdleIcon
                            v-if="!isAdding"
                            @touchstart="isTrashCanActive = true"
                            @touchend="isTrashCanActive = false; isDeleteModalShowing = true"
                            class="trash-can"
                            :class="isTrashCanActive ? 'color-feedback' : 'color-default'"
                            delay="1s"
                            duration="3s"
                            animation-type="fa-shake"
                            icon="trash-can"/>
                    </div>
            </template>
        </Modal>
    </Teleport>
    <Teleport to="body">
        <Modal isInfo
            :is-modal-showing="isErrorModalShowing"
            @close="isErrorModalShowing = false">
            <template #header>
                Name ungültig!
            </template>
            <template #body>
                {{ errorMsg }}
            </template>
        </Modal>
    </Teleport>
    <Teleport to="body">
        <Modal
            :is-modal-showing="isDeleteModalShowing"
            @confirm="$emit('deleteFencer', fencerName)"
            @close="isDeleteModalShowing = false; close()">
            <template #header>
                Fechter entfernen?
            </template>
            <template #body>
                {{ fencerName }} wird aus dem Tableau entfernt und alle
                Gefechte gegen {{ fencerName }} werden gelöscht.
            </template>
        </Modal>
    </Teleport>
</template>

<script>
import Modal from './Modal.vue'
import IdleIcon from './IdleIcon.vue'

export default {
    name: 'EditFencerModal',
    components: {
        Modal,
        IdleIcon
    },
    props: {
        isAdding: {
            type: Boolean,
            default: false
        },
        isModalShowing: Boolean,
        fencers: Array,
        fencerName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            newName: this.fencerName,
            isErrorModalShowing: false,
            isTrashCanActive: false,
            isDeleteModalShowing: false,
            errorMsg: ''
        }
    },
    methods: {
        isNameValid() {
            if (this.fencerName == this.newName) {
                this.close();
                return false;
            } else if (this.newName.replace(/\s/g, '').length == 0) {
                this.errorMsg = 'Namen dürfen nicht leer sein.';
                this.isErrorModalShowing = true;
                return false;
            } else if (this.fencers != null) {
                for (var fencer of this.fencers) {
                    if (fencer.name.toLowerCase() == this.newName.toLowerCase()) {
                        this.errorMsg = this.newName + ' existiert bereits im Tableau.';
                        this.isErrorModalShowing = true;
                        return false;
                    }
                }
            }
            return true;
        },
        addFencer() {
            if (this.isNameValid()) {
                this.$emit('newName', this.newName);
                this.close();
            }
        },
        close() {
            this.newName = this.fencerName;
            this.$emit('close');
        }
    },
    emits: ['close', 'newName', 'deleteFencer']
}
</script>

<style scoped>
.color-feedback {
    color: var(--secondary-color);
}

.color-default {
    color: red;
}

.wrapper {
    display: flex;
    align-items: center;
}

.trash-can {
    margin-left: auto;
    margin-right: auto;
    font-size: 3ex;
}

textarea, input {
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
    -webkit-appearance: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-touch-callout: initial;
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

.add-fencer {
    width: 100%;
}

.edit-fencer {
    width: 70%;
}

.fencer-input {
    background-image: linear-gradient(#000, #000);
    background-position: bottom 5px center;
    background-size: calc(100% - 10px) 1px;
    background-repeat: no-repeat;
    padding: 10px;
    color: #000000;
    font-size: medium;
}
</style>
