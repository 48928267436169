export default class Fencer {
    constructor(name) {
        this.name = name;
        this.matches = new Array();
    }

    getDoneMatches() {
        const doneMatches = new Array;
        for (const match of this.matches) {
            if (match.isDone()) {
                doneMatches.push(match);
            }
        }
        return doneMatches;
    }

    rename(name) {
        this.name = name;
    }

    getMatch(opponent) {
        for (const match of this.matches) {
            if (opponent.hasParticipated(match)) {
                return match;
            }
        }
    }

    getWinsAmt() {
        var winsAmt = 0;
        for (const match of this.getDoneMatches()) {
            if (this.hasWon(match)) {
                winsAmt++;
            }
        }
        return winsAmt;
    }

    getIndex1() {
        var index1 = this.getWinsAmt() / this.getDoneMatches().length;
        if (!isFinite(index1)) {
            return 0;
        }
        return (Math.round(index1 * 100) / 100).toFixed(2);
    }

    getIndex2() {
        var givenHits = 0;
        var receivedHits = 0;
        for (const match of this.getDoneMatches()) {
            givenHits += match.getScore(this);
            receivedHits += match.getOpponentScore(this);
        }
        return givenHits - receivedHits;
    }

    getGivenHits() {
        var givenHits = 0;
        for (const match of this.getDoneMatches()) {
            givenHits += match.getScore(this);
        }
        return givenHits;
    }

    isEqual(fencer) {
        return fencer != null && this.name === fencer.name;
    }

    hasParticipated(match) {
        return this.isEqual(match.fencerA) || this.isEqual(match.fencerB);
    }

    hasWon(match) {
        return this.isEqual(match.winner);
    }

    getPlace(fencers) {
        const sorted = fencers.slice().sort(function(fencerA, fencerB) {
            var fencerAIndex1 = fencerA.getIndex1();
            var fencerAIndex2 = fencerA.getIndex2();
            var fencerAGivenHits = fencerA.getGivenHits();
            var fencerBIndex1 = fencerB.getIndex1();
            var fencerBIndex2 = fencerB.getIndex2();
            var fencerBGivenHits = fencerB.getGivenHits();
            if (fencerAIndex1 > fencerBIndex1) {
                return -1;
            } else if (fencerAIndex1 < fencerBIndex1) {
                return 1;
            } else if (fencerAIndex2 > fencerBIndex2) {
                return -1;
            } else if (fencerAIndex2 < fencerBIndex2) {
                return 1;
            }
            return fencerBGivenHits - fencerAGivenHits;
        });
        return sorted.indexOf(this) + 1;
    }
}
