<template>
    <div
        @touchstart="isClipBoardActive = true"
        @touchend="isPulseActive = true; isRemainingModalShowing = !isRemainingModalShowing"
        class="footer">
        <template v-if="allMatchesAmt - winsAmt > 0 || fencers.length < 2">
            <h4>Ausstehende Gefechte: {{ allMatchesAmt - winsAmt }}</h4>
            <InteractiveIcon
                icon="clipboard-list"
                animation-type="fa-bounce"
                :duration="410"
                :is-pulse-active="isPulseActive"
                @toggle-pulse="(state) => isPulseActive = state"
                :is-active="isClipBoardActive"
                @toggle-active="(state) => isClipBoardActive = state" />
        </template>
        <template v-else>
            <h4>Auswertung</h4>
            <IdleIcon
                duration="3s"
                id="award"
                delay="2s"
                animationType="fa-shake"
                icon="award"/>
        </template>
      </div>
      <Modal isInfo
          :isHeader="false"
          :is-modal-showing="isRemainingModalShowing"
          @close="isRemainingModalShowing = false; isClipBoardActive = false">
        <template #body>
            <ol start="0">
                <li
                    v-for="(match, index) in order"
                    :key="index">
                    {{ match.fencerB.name + ' - ' + match.fencerA.name }}
                </li>
            </ol>
        </template>
    </Modal>
</template>

<script>
import Modal from './Modal.vue'
import IdleIcon from './IdleIcon.vue'
import InteractiveIcon from './InteractiveIcon.vue'
import Kneser from '../js/Kneser.js'
import Heuristic from '../js/Heuristic.js'
import OrderTemplate from '../js/OrderTemplate.js'

export default {
    name: 'Footer',
    components: {
        Modal,
        InteractiveIcon,
        IdleIcon
    },
    props: {
        fencers: Array
    },
    data() {
        return {
            isRemainingModalShowing: false,
            isClipBoardActive: false,
            isPulseActive: false,
            order: new Array(),
            mainOrder: new Array(),
            matchHistory: new Array(),
            oldFencers: new Array(),
        }
    },
    computed: {
        winsAmt() {
            var winsAmt = 0;
            for (const fencer of this.fencers) {
                winsAmt += fencer.getWinsAmt();
            }
            return winsAmt;
        },
        allMatchesAmt() {
            const length = this.fencers.length;
            return (length * length - length) / 2;
        }
    },
    watch: {
        fencers: {
            handler() {
                if (this.fencers.length < 2) {
                    this.order.splice(0, this.order.length);
                }
                if (this.oldFencers.length != this.fencers.length &&
                    this.doneMatches(this.fencers).length == 0) {
                    this.staticOrder();
                } else {
                    this.dynamicOrder();
                }
                this.oldFencers = structuredClone(this.fencers);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        staticOrder() {
            const allMatches = this.allMatches();
            this.matchHistory = new Array();
            if (this.fencers.length < 11) {
                console.log('used template');
                const orderTemplate =
                    new OrderTemplate(this.fencers, allMatches);
                this.order = orderTemplate.order;
            } else {
                console.log('used palmer');
                const kneser = new Kneser(allMatches);
                this.order = kneser.getHamilton();
            }
            this.mainOrder = this.order.slice();
        },
        dynamicOrder() {
            const allMatches = this.allMatches();
            const addedMatch = this.addedMatch();
            const deletedMatches = this.deletedMatches();
            var shouldUseHeuristic = false;
            if (addedMatch != null) {
                this.matchHistory.push(addedMatch);
                if (addedMatch.isEqual(this.order[0])) {
                    this.order.splice(0, 1);
                } else {
                    shouldUseHeuristic = true;
                }
            }
            if (deletedMatches != null) {
                for (const match of deletedMatches) {
                    this.matchHistory
                        .splice(this.matchHistory.indexOf(match), 1);
                }
                const mainOrderPart =
                    this.mainOrder.slice(0, this.matchHistory.length);
                for (const i in this.matchHistory) {
                    if (!this.matchHistory[i].isEqual(mainOrderPart[i])) {
                        shouldUseHeuristic = true;
                        break;
                    }
                }
                this.order = this.mainOrder.slice(this.matchHistory.length);
            }
            if (this.oldFencers.length != this.fencers.length ||
                shouldUseHeuristic) {
                console.log('used heuristic');
                const heuristic =
                    new Heuristic(this.fencers, allMatches, this.matchHistory);
                this.order = heuristic.order;
            }
        },
        doneMatches(fencers) {
            var matches = new Array();
            for (const fencer of fencers) {
                const doneMatches = fencer.matches.filter(e => e.winner != null);
                for (const match of doneMatches) {
                    if (!matches.includes(match)) {
                        matches.push(match);
                    }
                }
            }
            return matches;
        },
        allMatches() {
            var matches = new Array();
            for (const f in this.fencers) {
                for (var i = 0; i < f; i++) {
                    matches.push(this.fencers[f].matches[i]);
                }
            }
            return matches;
        },
        addedMatch() {
            const doneNew = this.doneMatches(this.fencers);
            const doneOld = this.doneMatches(this.oldFencers);
            for (const matchA of doneNew) {
                if (doneOld.length == 0) {
                    return matchA;
                }
                for (const i in doneOld) {
                    const matchB = doneOld[i];
                    if (matchA.isEqual(matchB)) {
                        break;
                    }
                    if (i == doneOld.length - 1) {
                        return matchA;
                    }
                }
            }
            return null;
        },
        deletedMatches() {
            const matches = new Array();
            const done = this.doneMatches(this.fencers);
            const hist = this.matchHistory;
            for (const matchA of hist) {
                if (done.length == 0) {
                    matches.push(matchA);
                }
                for (const i in done) {
                    const matchB = done[i];
                    if (matchA.isEqual(matchB)) {
                        break;
                    }
                    if (i == done.length - 1) {
                        matches.push(matchA);
                    }
                }
            }
            return matches.length > 0 ? matches : null;
        },
        printMatches(matches) {
            if (matches == null) {
                console.log(null);
            }
            for (const match of matches) {
                console.log(match.fencerA.name + ' - ' + match.fencerB.name);
            }
            console.log();
        }
    }
}
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3em;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

#award {
    padding: 0;
}
</style>
