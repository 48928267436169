export default class Match {
    constructor(fencerA, scoreA, fencerB, scoreB, winner) {
        this.fencerA = fencerA;
        this.scoreA = scoreA;
        this.fencerB = fencerB;
        this.scoreB = scoreB;
        this.winner = winner;
    }

    isDone() {
        return this.winner != null;
    }

    getOpponent(fencer) {
        if (fencer.hasParticipated(this)) {
            return fencer.isEqual(this.fencerA) ? this.fencerB : this.fencerA;
        }
        return null;
    }

    setScore(fencer, score) {
        if (fencer.hasParticipated(this)) {
            if (fencer.isEqual(this.fencerA)) {
                this.scoreA = score;
            } else {
                this.scoreB = score;
            }
        }
    }

    getScore(fencer) {
        if (fencer.hasParticipated(this)) {
            return fencer.isEqual(this.fencerA) ? this.scoreA : this.scoreB;
        }
        return -1;
    }

    getOpponentScore(fencer) {
        if (fencer.hasParticipated(this)) {
            return fencer.isEqual(this.fencerA) ? this.scoreB : this.scoreA;
        }
        return -1;
    }

    isEqual(match) {
        return this.fencerA.isEqual(match.fencerA) &&
            this.fencerB.isEqual(match.fencerB) ||
            this.fencerA.isEqual(match.fencerB) &&
            this.fencerB.isEqual(match.fencerA);
    }
}
