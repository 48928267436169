export default class OrderTemplate {
    constructor(fencers, matches) {
        const order = new Array();
        this.fencers = fencers;
        this.order = order;
        this.matches = matches;
        switch(fencers.length) {
            case 2:
                this.matchOrder([
                    [1,2]
                ]);
                break;

            case 3:
                this.matchOrder([
                    [1,2],
                    [1,3],
                    [2,3]
                ]);
                break;

            case 4:
                this.matchOrder([
                    [1,2],
                    [3,4],
                    [1,3],
                    [2,4],
                    [1,4],
                    [2,3]
                ]);
                break;

            case 5:
                this.matchOrder([
                    [1,2],
                    [3,4],
                    [5,1],
                    [2,3],
                    [5,4],
                    [1,3],
                    [2,5],
                    [4,1],
                    [3,5],
                    [4,2]
                ]);
                break;

            case 6:
                this.matchOrder([
                    [1,2],
                    [4,5],
                    [2,3],
                    [5,6],
                    [3,1],
                    [6,4],
                    [2,5],
                    [1,4],
                    [5,3],
                    [1,6],
                    [4,2],
                    [3,6],
                    [5,1],
                    [3,4],
                    [6,2]
                ]);
                break;

            case 7:
                this.matchOrder([
                    [1,4],
                    [2,5],
                    [3,6],
                    [7,1],
                    [5,4],
                    [2,3],
                    [6,7],
                    [5,1],
                    [4,3],
                    [6,2],
                    [5,7],
                    [3,1],
                    [4,6],
                    [7,2],
                    [3,5],
                    [1,6],
                    [2,4],
                    [7,3],
                    [6,5],
                    [1,2],
                    [4,7]
                ]);
                break;

            case 8:
                this.matchOrder([
                    [2,3],
                    [1,5],
                    [7,4],
                    [6,8],
                    [1,2],
                    [3,4],
                    [5,6],
                    [8,7],
                    [4,1],
                    [5,2],
                    [8,3],
                    [6,7],
                    [4,2],
                    [8,1],
                    [7,5],
                    [3,6],
                    [2,8],
                    [5,4],
                    [6,1],
                    [3,7],
                    [4,8],
                    [2,6],
                    [3,5],
                    [1,7],
                    [4,6],
                    [8,5],
                    [7,2],
                    [1,3]
                ]);
                break;

            case 9:
                this.matchOrder([
                    [1,9],
                    [2,8],
                    [3,7],
                    [4,6],
                    [1,5],
                    [2,9],
                    [8,3],
                    [7,4],
                    [6,5],
                    [1,2],
                    [9,3],
                    [8,4],
                    [7,5],
                    [6,1],
                    [3,2],
                    [9,4],
                    [5,8],
                    [7,6],
                    [3,1],
                    [2,4],
                    [5,9],
                    [8,6],
                    [7,1],
                    [4,3],
                    [5,2],
                    [6,9],
                    [8,7],
                    [4,1],
                    [5,3],
                    [6,2],
                    [9,7],
                    [1,8],
                    [4,5],
                    [3,6],
                    [2,7],
                    [9,8]
                ]);
                break;

            case 10:
                this.matchOrder([
                    [1,6],
                    [2,7],
                    [3,8],
                    [4,9],
                    [5,10],
                    [7,1],
                    [8,2],
                    [9,3],
                    [10,4],
                    [6,5],
                    [1,8],
                    [2,9],
                    [3,10],
                    [4,6],
                    [5,7],
                    [9,1],
                    [10,2],
                    [6,3],
                    [7,4],
                    [8,5],
                    [10,1],
                    [2,6],
                    [3,7],
                    [4,8],
                    [9,5],
                    [1,2],
                    [3,4],
                    [6,7],
                    [8,9],
                    [5,1],
                    [2,3],
                    [6,10],
                    [7,8],
                    [4,5],
                    [10,9],
                    [3,7],
                    [2,4],
                    [6,8],
                    [7,9],
                    [5,2],
                    [8,10],
                    [1,4],
                    [5,3],
                    [9,6],
                    [7,10]
                ]);
                break;
        }
    }

    matchOrder(ordering) {
        for (const lineUp of ordering) {
            const fencerA = this.fencers[lineUp[0] - 1];
            const fencerB = this.fencers[lineUp[1] - 1];
            for (const match of this.matches) {
                if (fencerA.hasParticipated(match) && fencerB.hasParticipated(match)) {
                    this.order.push(match);
                    break;
                }
            }
        }
    }
}
