<template>
    <td
        v-if="!fencer.isEqual(opponent)"
        :class="isActive ? 'color-feedback' : 'default-bg'"
        @touchstart="$emit('isActive', true)"
        v-touch:hold="() => isDeleteModalShowing = match.isDone()"
        @click="isMatchModalShowing = true"
        @touchend="$emit('isActive', false)">
        {{ scoreDisplay }}
    </td>
    <td v-else class="own-cell"></td>
    <Teleport to="body">
        <Modal
            :is-modal-showing="isDeleteModalShowing"
            @confirm="$emit('deleteMatch', match)"
            @close="isDeleteModalShowing = false;">
            <template #header>
                Gefecht entfernen?
            </template>
            <template #body>
                Das Gefecht zwischen {{ fencer.name }} und {{ opponent.name }}
                wird aus dem Tableau entfernt. </template>
        </Modal>
    </Teleport>
    <Teleport to="body">
        <Modal
            :is-modal-showing="isMatchModalShowing"
            @confirm="addMatch"
            @close="isMatchModalShowing = false;">
            <template #header>
                Gefecht eintragen
            </template>
            <template #body>
                <div class="wrapper">
                    {{ fencer.name }}
                    <Numberpicker
                        :init="match.getScore(fencer)"
                        :max="points"
                        :matchType="fencer.isEqual(tempWinner) ? 'V' : 'D'"
                        @change-value="changeScoreFencer"
                        @toggle-match-type="toggleMatchType"
                        :min="0"/>
                    <Numberpicker
                        :init="match.getScore(opponent)"
                        :max="points"
                        :matchType="opponent.isEqual(tempWinner) ? 'V' : 'D'"
                        @change-value="changeScoreOpponent"
                        @toggle-match-type="toggleMatchType"
                        :min="0"/>
                    {{ opponent.name }}
                </div>
            </template>
        </Modal>
    </Teleport>
</template>

<script>
import Modal from './Modal.vue'
import Match from '../js/Match.js'
import Numberpicker from './Numberpicker.vue'

export default {
    name: 'MatchCell',
    components: {
        Numberpicker,
        Modal
    },
    props: {
        initMatch: Object,
        fencer: Object,
        opponent: Object,
        points: Number,
        isActive: Boolean,
    },
    data() {
        return {
            isDeleteModalShowing: false,
            isMatchModalShowing: false,
            match: null,
            tempWinner: this.fencer
        }
    },
    watch: {
        initMatch: {
            handler(newMatch) {
                this.match = new Match(
                    newMatch.fencerA,
                    newMatch.scoreA,
                    newMatch.fencerB,
                    newMatch.scoreB,
                    newMatch.winner,
                );
                if (this.match.winner != null) {
                    this.tempWinner = this.match.winner;
                }
            },
            immediate: true
        }
    },
    computed: {
        scoreDisplay() {
            if (!this.match.isDone()) {
                return '';
            }
            var scoreString = '';
            if (this.fencer.isEqual(this.tempWinner)) {
                scoreString += 'V';
            }
            return scoreString + this.match.getScore(this.fencer);
        }
    },
    methods: {
        changeScoreFencer(score) {
            this.match.setScore(this.fencer, score);
            this.updateWinner();
        },
        changeScoreOpponent(score) {
            this.match.setScore(this.opponent, score);
            this.updateWinner();
        },
        updateWinner() {
            if (this.match.getScore(this.fencer) > this.match.getScore(this.opponent)) {
                this.tempWinner = this.fencer;
            } else if (this.match.getScore(this.fencer) < this.match.getScore(this.opponent)) {
                this.tempWinner = this.opponent;
            }
        },
        addMatch() {
            this.match.winner = this.tempWinner;
            this.$emit('addMatch', this.match);
        },
        toggleMatchType() {
            if (this.match.getScore(this.fencer) == this.match.getScore(this.opponent)) {
                this.tempWinner = this.tempWinner.isEqual(this.fencer) ? this.opponent : this.fencer;
            }
        }
    },
    emits: ['addMatch', 'deleteMatch', 'isActive']
}
</script>

<style scoped>
.wrapper {
    text-align: center;
}
.wrapper > * {
    margin: 1em auto 1em auto;
}

.color-feedback {
    background-color: var(--secondary-color);
}

.default-bg {
    background-color: white;
}

.own-cell {
    background-color: black;
}
</style>
