<template>
    <div class="wrapper">
        <button
            :disabled="isSubBtnDisabled"
            @click="sub"
            @touchend="isSubBtnActive = false"
            @touchstart="isSubBtnActive = true"
            :class="subBtnFeedback"
            class="picker-btn sub-btn">
            -
        </button>
        <div
            @click="toggleMatchType"
            @touchend="isDisplayActive = false"
            @touchstart="isDisplayActive = true"
            :class="{'color-feedback': isDisplayActive && matchType != ''}"
            class="number-display">
            {{ matchType + value }}
        </div>
        <button
            :disabled="isAddBtnDisabled"
            @click="add"
            @touchend="isAddBtnActive = false"
            @touchstart="isAddBtnActive = true"
            :class="addBtnFeedback"
            class="picker-btn add-btn">
            +
        </button>
    </div>
</template>

<script>

export default {
    name: 'Numberpicker',
    props: {
        init: {
            type: Number,
            default: 5
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 15
        },
        matchType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            value: this.init,
            isAddBtnActive: false,
            isSubBtnActive: false,
            isDisplayActive: false
        }
    },
    computed: {
        isAddBtnDisabled() {
            return this.value >= this.max;
        },
        isSubBtnDisabled() {
            return this.value <= this.min;
        },
        addBtnFeedback() {
            if (this.isAddBtnDisabled) {
                return 'btn-disabled';
            } else if (this.isAddBtnActive) {
                return 'color-feedback';
            } else {
                return 'default-bg';
            }
        },
        subBtnFeedback() {
            if (this.isSubBtnDisabled) {
                return 'btn-disabled';
            } else if (this.isSubBtnActive) {
                return 'color-feedback';
            } else {
                return 'default-bg';
            }
        }
    },
    methods: {
        toggleMatchType() {
            this.$emit('toggleMatchType');
        },
        changeValue(newValue) {
            if (newValue <= this.max && newValue >= this.min) {
                this.value = newValue;
                this.$emit('changeValue', newValue);
            }
            if (newValue == this.max - 1 || newValue == this.min + 1) {
                this.isSubBtnActive = false;
                this.isAddBtnActive = false;
            }
        },
        add() {
            this.changeValue(this.value + 1);
        },
        sub() {
            this.changeValue(this.value - 1);
        }
    },
    emits: ['changeValue', 'toggleMatchType']
}
</script>

<style scoped>
.color-feedback {
    background-color: var(--secondary-color);
}

.btn-disabled {
    background-color: lightgrey;
}

.default-bg {
    background-color: white;
}

.wrapper {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    display: flex;
}

.picker-btn {
    color: var(--thernary-color);
    font-size: large;
    margin: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.add-btn {
    border-radius: 0px 10px 10px 0px;
}

.sub-btn {
    border-radius: 10px 0px 0px 10px;
}

.number-display {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
</style>
