export default class Heuristic {
    constructor(fencers, allMatches, matchHistory) {
        this.allMatches = allMatches;
        this.fencers = fencers;
        this.matchHistory = matchHistory;
        this.matches = this.allMatches.slice();
        this.used = new Map();
        this.dist = new Map();
        this.order = new Array();

        this.run();
        console.log('collisions: ' + this.collisions(this.order));
    }

    run() {
        for (const fencer of this.fencers) {
            this.used.set(fencer, 0);
            this.dist.set(fencer, Infinity);
        }
        if (this.matchHistory != null && this.matchHistory.length > 0) {
            for (const match of this.matchHistory) {
                this.addMatch(match);
            }
        } else {
            const rdm = Math.floor(Math.random() * this.matches.length);
            this.addMatch(this.matches[rdm]);
        }
        for (const {} of this.allMatches) {
            const nextFencers = this.fencers
                .slice()
                .sort(this.compare.bind(this));
            outer:
            for (var k = 1; k < nextFencers.length; k++) {
                for (var l = k - 1; l + k < nextFencers.length; l++) {
                    for (const match of this.matches) {
                        if (nextFencers[l].hasParticipated(match) &&
                            nextFencers[l + k].hasParticipated(match)) {
                            this.addMatch(match);
                            break outer;
                        }
                    }
                }
            }
        }
        this.order.splice(0, this.matchHistory.length);
    }

    collisions(order) {
        var collisions = 0;
        for (var i = 0; i < order.length - 1; i++) {
            if (order[i].fencerA.hasParticipated(order[i + 1]) ||
                order[i].fencerB.hasParticipated(order[i + 1])) {
                collisions++;
            }
        }
        return collisions;
    }

    addMatch(match) {
        this.order.push(match);
        this.matches.splice(this.matches.indexOf(match), 1);
        this.incrUse(match.fencerA);
        this.incrUse(match.fencerB);
        this.fencers.forEach(f => this.incrDist(f));
        this.dist.set(match.fencerA, 1);
        this.dist.set(match.fencerB, 1);
    }

    incrUse(fencer) {
        this.used.set(fencer, this.used.get(fencer) + 1);
    }

    incrDist(fencer) {
        this.dist.set(fencer, this.dist.get(fencer) + 1);
    }

    compare(fencerA, fencerB) {
        if (this.used.get(fencerB) >= this.fencers.length - 1 &&
            this.used.get(fencerA) < this.fencers.length - 1) {
            return +1;
        }
        if (this.used.get(fencerA) >= this.fencers.length - 1 &&
            this.used.get(fencerB) < this.fencers.length - 1) {
            return -1;
        }
        if (fencerA.hasParticipated(this.order[this.order.length - 1]) &&
            !fencerB.hasParticipated(this.order[this.order.length - 1])) {
            return +1;
        }
        if (fencerB.hasParticipated(this.order[this.order.length - 1]) &&
            !fencerA.hasParticipated(this.order[this.order.length - 1])) {
            return -1;
        }
        if (this.used.get(fencerA) < this.used.get(fencerB)) {
            return +1;
        }
        if (this.used.get(fencerA) > this.used.get(fencerB)) {
            return -1;
        }
        if (this.dist.get(fencerA) > this.dist.get(fencerB)) {
            return +1;
        }
        if (this.dist.get(fencerA) < this.dist.get(fencerB)) {
            return -1;
        }
        return 0;
    }
}
