<template>
    <div
        v-if="isModalShowing"
        @click="isInfo && close()"
        class="modal">
        <div
            @click.stop=""
            class="modal-container">
            <div 
                v-if="isHeader"
                class="modal-header">
                <h4><slot name="header"/></h4>
            </div>
            <div
                :class="{'body-rounded': isInfo}"
                class="modal-body">
                <slot name="body"/>
            </div>
            <div
                v-if="!isInfo"
                class="modal-footer">
                <button
                    @click="close(); $emit('abort')"
                    @touchend="isBtnLeftActive = false"
                    @touchstart="isBtnLeftActive = true"
                    :class="isBtnLeftActive ? 'color-feedback' : 'default-bg'"
                    class="dialog-btn dialog-btn-left">
                    Abbrechen
                </button>
                <button
                    @click="confirm"
                    @touchend="isBtnRightActive = false"
                    @touchstart="isBtnRightActive = true"
                    :class="isBtnRightActive ? 'color-feedback' : 'default-bg'"
                    class="dialog-btn dialog-btn-right">
                    Ok
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        isModalShowing: Boolean,
        isInfo: {
            type: Boolean,
            default: false
        },
        isHeader: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isBtnRightActive: false,
            isBtnLeftActive: false
        }
    },
    methods: {
        close() {
            this.isBtnRightActive = false;
            this.isBtnLeftActive = false;
            this.$emit('close');
        },
        confirm() {
            this.$emit('confirm');
            this.close();
        }
    },
    emits: ['close', 'confirm', 'abort']
}
</script>

<style scoped>
.color-feedback {
    background-color: var(--secondary-color);
}

.default-bg {
    background-color: white;
}

.body-rounded {
    border-radius: 0px 0px 10px 10px;
}

.modal {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.modal-body {
    padding: 16px;
    background-color: white;
    max-height: 100%;
    overflow-y: auto;
}

.fixed-header {
    height: 80%;
}

.modal-container {
    position: relative;
    overflow-y: auto;
    border-radius: 10px;
    display: block;
    margin: auto;
    padding: 0;
    max-height: 80%;
    width: 80%;
    max-width: 400px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.modal-header {
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    font-size: large;
    font-weight: bold;
    padding: 16px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
}

.modal-footer {
    padding: 0;
    border-radius: 0px 0px 10px 10px;
    display: flex;
}

.dialog-btn {
    margin: 0;
    flex: 1 1 auto;
    color: var(--thernary-color);
    width: 50%;
    text-align: center;
    border: none;
    font-size: medium;
    padding: 15px;
}

.dialog-btn-right {
    border-bottom-right-radius: 10px;
}

.dialog-btn-left {
    border-bottom-left-radius: 10px;
}

</style>
