<template>
    <div
        v-if="fencers != null && fencers.length > 0"
        id="table">
        <table
            class="unselectable">
            <tr>
                <td>Nr.</td>
                <td>Name</td>
                <td
                    v-for="n in fencers.length"
                    :key="n">{{ n }}</td>
            </tr>
            <tr
                v-for="(fencer, index1) in fencers"
                :key="fencer.name">
                <td>{{ index1 + 1 }}</td>
                <FencerCell
                    @rename-fencer="(newName) => $emit('renameFencer', {oldName: fencer.name, newName: newName})"
                    @delete-fencer="(deletedName) => $emit('deleteFencer', deletedName)"
                    :fencers="fencers"
                    :fencer="fencer"/>
                <MatchCell
                    v-for="(match, index2) in fencer.matches"
                    :key="fencer.name + match.getOpponent(fencer).name"
                    :fencer="fencer"
                    :opponent="match.getOpponent(fencer)"
                    :init-match="match"
                    :is-active="cellsActive[index1][index2]"
                    @is-active="(isActive) => activeEntanglement(isActive, index1, index2)"
                    :points="points"
                    @add-match="(match) => $emit('addMatch', match)"
                    @delete-match="(match) => $emit('deleteMatch', match)"/>
            </tr>
        </table>
    </div>
</template>

<script>
import FencerCell from './FencerCell.vue'
import MatchCell from './MatchCell.vue'

export default {
    name: 'Table',
    components: {
        FencerCell,
        MatchCell
    },
    props: {
        fencers: Array,
        points: Number,
    },
    data() {
        return {
            cellsActive: null,
        }
    },
    watch: {
        fencers: {
            handler(newFencers) {
                this.cellsActive = Array.from(
                    Array(newFencers.length).fill(false),
                    () => new Array(newFencers.length).fill(false)
                );
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        activeEntanglement(isActive, index1, index2) {
            this.cellsActive[index1][index2] =
            this.cellsActive[index2][index1] = isActive;
        }
    },
    emits: ['renameFencer', 'deleteFencer', 'addMatch', 'deleteMatch']
}
</script>

<style scoped>
.color-feedback {
    background-color: var(--secondary-color);
}

.default-bg {
    background-color: white;
}

.own-cell {
    background-color: black;
}

#table {
    width: 100%;
    height: 100%;
    overflow: auto;
}

::-webkit-scrollbar {
    display: none;
}
</style>

<style>
table {
    border-collapse: collapse;
}

td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    max-width: 6em;
    min-width: calc(1.7em + 20px);
    height: calc(1.7em + 20px);
    padding: 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>
