<template>
    <header>
        <h2>Tableau</h2>
        <InteractiveIcon isToolbarItem
            icon="user-plus"
            animation-type="fa-bounce"
            :duration="410"
            :is-active="isUserPlusActive"
            @toggle-active="(state) => isUserPlusActive = state"
            @touchend="isEditFencerModalShowing = true"
            id="user-plus"/>
        <div v-click-outside="closeDropdown">
            <InteractiveIcon isToolbarItem
                icon="gear"
                animation-type="fa-spin"
                :duration="340"
                :is-active="isGearActive"
                @toggle-active="(state) => isGearActive = state"
                @touchend="isDropdownShowing = !isDropdownShowing"
                id="gear"/>
            <div
                id="dropdown"
                class="dropdown-content"
                :class="{show: isDropdownShowing}">
                <DropdownItem
                    content="Punkte/Gefecht"
                    @touchend="closeDropdown(); isPointsModalShowing = true"/>
                <DropdownItem
                    content="neues Tableau"
                    @touchend="closeDropdown(); $emit('deleteAllFencers')"/>
                <DropdownItem
                    content="Tableau leeren"
                    @touchend="closeDropdown(); $emit('deleteAllMatches')"/>
            </div>
        </div>
    </header>
    <Teleport to="body">
        <Modal
            :is-modal-showing="isPointsModalShowing"
            @confirm="confirmPoints"
            @abort="pointsUnconfirmed = points"
            @close="isPointsModalShowing = false">
            <template #header>
                Punkte/Gefecht
            </template>
            <template #body>
                <Numberpicker
                    :init="points"
                    :min="1"
                    @change-value="(p) => pointsUnconfirmed = p"/>
            </template>
        </Modal>
    </Teleport>
    <Teleport to="body">
        <Modal isInfo
            :is-modal-showing="isPointsErrorModalShowing"
            @close="isPointsErrorModalShowing = false">
            <template #header>
                Punkte/Gefecht ungültig!
            </template>
            <template #body>
                Es wurden bereits Gefechte eingetragen. Bitte leere das
                Tableau, bevor du die Punkte pro Gefecht festlegst.
            </template>
        </Modal>
    </Teleport>
    <Teleport to="body">
        <EditFencerModal isAdding
            :is-modal-showing="isEditFencerModalShowing"
            :fencers="fencers"
            @new-name="(newName) => $emit('newName', newName)"
            @close="isEditFencerModalShowing = false; isUserPlusActive = false"/>
    </Teleport>
</template>

<script>
import InteractiveIcon from './InteractiveIcon.vue'
import DropdownItem from './DropdownItem.vue'
import Modal from './Modal.vue'
import Numberpicker from './Numberpicker.vue'
import EditFencerModal from './EditFencerModal.vue'

export default {
    name: 'Toolbar',
    props: {
        fencers: Array
    },
    components: {
        InteractiveIcon,
        DropdownItem,
        Modal,
        Numberpicker,
        EditFencerModal
    },
    data() {
        return {
            points: 5,
            pointsUnconfirmed: 5,
            isGearActive: false,
            isUserPlusActive: false,
            isDropdownShowing: false,
            isPointsModalShowing: false,
            isEditFencerModalShowing: false,
            isPointsErrorModalShowing: false
        }
    },
    methods: {
        closeDropdown() {
            this.isDropdownShowing = false;
            this.isGearActive = false;
        },
        confirmPoints() {
            if (this.points == this.pointsUnconfirmed) {
                return;
            }
            for (const fencer of this.fencers) {
                if (fencer.getWinsAmt() > 0) {
                    this.isPointsErrorModalShowing = true;
                    return;
                }
            }
            this.points = this.pointsUnconfirmed;
            this.$emit('points', this.points);
        }
    },
    emits: ['points', 'newName', 'deleteAllFencers', 'deleteAllMatches']
}
</script>

<style scoped>
header {
    position: fixed;
    background-color: var(--primary-color);
    color: #FFFFFF;
    width: 100%;
    height: var(--header-size);
    top: 0;
    padding-left: 10px;
    padding-right: 0px;
    display: flex;
    align-items: center;
}

#user-plus {
    margin-left: auto;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    z-index: 1;
}

.show {display: block;}
</style>
