<template>
    <div
        @touchend="animationPulse"
        @touchstart="toggleActive"
        :class="{'wrapper-toolbar': isToolbarItem}">
        <font-awesome-icon
            :icon="icon"
            class="icon"
            :class="[ isAnimationPlaying ? animationType : '', isActive ? 'color-feedback' : '' ]"/>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'InteractiveIcon',
    components: {
        FontAwesomeIcon
    },
    props: {
        icon: String,
        animationType: String,
        duration: Number,
        isActive: Boolean,
        isPulseActive: Boolean,
        isToolbarItem: Boolean
    },
    data() {
        return {
            isAnimationPlaying: false,
        }
    },
    watch: {
        isPulseActive: {
            handler(value) {
                if (value) {
                    this.animationPulse();
                }
            },
            immediate: true
        }
    },
    methods: {
        toggleActive() {
            this.$emit('toggleActive', !this.isActive);
        },
        animationPulse() {
            this.isAnimationPlaying = true;
            setTimeout(() => {
                this.isAnimationPlaying = false;
            }, this.duration);
            this.$emit('togglePulse', !this.isPulseActive);
        }
    },
    emits: ['toggleActive', 'togglePulse']
}
</script>

<style scoped>
.color-feedback {
    color: var(--secondary-color);
}

.icon {
    font-size: 2.5ex;
    display: block;
}

.wrapper-toolbar {
    text-align: center;
    padding: calc(var(--header-size)/2 - 2.5ex/2) 1ex calc(var(--header-size)/2 - 2.5ex/2) 1ex;
}

</style>
